// Load NPM modules
import React, { Component } from 'react';
import { Container } from 'react-grid-system';
import { Button } from 'antd';

// Load System components
import './App.css';
import Editor from './components/Editor.js';

class App extends Component {
  render() {
    return (
      <div className="App">
        <h1>Welcome!</h1>
        <div className="about">
          Hello! My name is Niharika and I like to code (primarily javascript - I'm 'hooked' (eh-eh) to React at the moment!). I also periodically write a <a target="_blank" href='https://www.untechly.com/'>newsletter</a> for folks interested in tech news who want a jargon-free news source. I'm an open-source enthusiast, a former <a href="https://www.outreachy.org/">Outreachy</a> intern and worked on <a href="https://github.com/mozilla-services/screenshots/>">Firefox Screenshots</a> with the Test Pilot team at Mozilla in my final year of uni. I'm currently working as a web engineer at <a href='https://monzo.com/'>Monzo</a>. Feel free to checkout my <a href='https://resume.creddle.io/resume/5dy9a5vonui'>resume</a> or some of my social stuff linked below. In the meantime, here's a photo of my face, filter away!
          <br /><br />
          Oh, I also made an online version of an iconic childhood game, it's called <a href="https://nameplaceanimalthing.online/">Name, Place, Animal, Thing
          </a>. Check it out!
        </div>
        <div className="social-buttons">
          <Button href="https://www.twitter.com/niharikak101" target="_blank" shape="circle" icon="twitter" />
          <Button href="https://www.github.com/nihakhanna" target="_blank" shape="circle" icon="github" />
          <Button href="https://linkedin.com/in/niharikak" target="_blank" shape="circle" icon="linkedin" />
          <Button href="https://medium.com/@nihakhanna" target="_blank" shape="circle" icon="medium" />
          <Button href="https://instagram.com/nihakhanna" target="_blank" shape="circle" icon="instagram" />
        </div>
        <Container fluid className="workspace-container">
          <Editor />
        </Container>
      </div>
    );
  }
}

export default App;

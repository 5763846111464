import React, { Component } from 'react';
import { Row, Col } from 'react-grid-system';
import autoBind from 'react-autobind';
import { Slider } from 'antd';

import image from '../static/photo.jpg';

class Editor extends Component {
    constructor(props) {
        super(props);
        let width = Math.min((window.innerWidth - 20), 424)
        this.state = {
            width, 
            height: 595,
            grayscale: 0,
            contrast: 100,
            brightness: 100,
            hue: 0,
            blur: 0,
            invert: 0
        };
        autoBind(this);
    }

    componentDidMount() {
      //  this.addListeners();
    }

    componentDidUpdate() {
       // this.addListeners();
    }


    render() {
        return <Row>
            <Col xs={12} md={8}>
                <div className="image-container">
                    <img style={{width: '100%', filter: `grayscale(${this.state.grayscale}%) contrast(${this.state.contrast}%) brightness(${this.state.brightness}%) hue-rotate(${this.state.hue}deg) blur(${this.state.blur}px) invert(${this.state.invert}%)`}} alt="person" src={image}></img>
                </div>
            </Col>
            <Col xs={12} md={4}>
                <div className="palette-container">
                    Contrast<Slider max={200} value={this.state.contrast} onChange={(value) => this.setState({contrast: value})}/>
                    Grayscale<Slider onChange={(value) => this.setState({grayscale: value})}/>
                    Brightness<Slider max={200} value={this.state.brightness} onChange={(value) => this.setState({brightness: value})}/>
                    Hue<Slider max={360} value={this.state.hue} onChange={(value) => this.setState({hue: value})}/>
                    Blur<Slider max={50} value={this.state.blur} onChange={(value) => this.setState({blur: value})}/>
                    Invert<Slider max={100} value={this.state.invert} onChange={(value) => this.setState({invert: value})}/>
                </div>
            </Col>
        </Row>
    }
}

export default Editor;